<template>
  <div class="fill-height">
    <v-container class="createaccount-page pb-12 mb-12">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">
        {{ $t("Search") }}
      </h1>

      <div class="gcse-search"></div>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Search": "Search",
    "PageDescription": "Search on Babiry"
    },
    "sv": {
    "Search": "Sök",
    "PageDescription": "Sök på Barndagboken"
    }
    }
</i18n>

<script>
export default {
  name: "spage_search",
  metaInfo() {
    return {
      title: this.$t("Search"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Search") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({}),
  mounted() {
    let script = document.createElement("script");
    script.async = true;
    script.src = "https://cse.google.com/cse.js?cx=" + process.env.VUE_APP_GOOGLE_SEARCH_KEY;
    document.head.appendChild(script);
  },
  methods: {},
};
</script>

<style>
.gsc-control-wrapper-cse {
  font-family: "Roboto", sans-serif !important;
}
.gsc-control-cse {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.gsc-search-button {
  height: 56px !important;
  min-width: 56px !important;
  margin: 0 !important;
}
.gsc-search-button svg {
  height: 18px !important;
  width: 18px !important;
}
.gsc-input-box {
  padding: 0 !important;
}
.gsc-input {
   margin: 0 !important;
   padding: 8px 0 8px !important;
   padding-left: 6px !important;
   padding-right: 6px !important;
   line-height: 20px !important;
   font-size: 16px !important;
}
.gsc-search-box {
  margin-bottom: 20px !important;
}
.gsc-search-box td {
  padding: 0 !important;
}
.gcsc-more-maybe-branding-root, .gcsc-find-more-on-google-root, .gsc-adBlock {
  display: none !important;
}
</style>